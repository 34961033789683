export const useZoomTokenStorage = () => {
   const clientSDKToken = useCookie<string | null>('clientSDKToken');
   const therapistSDKToken = useCookie<string | null>('therapistSDKToken');

   const isClientSDKAuth = computed(() => clientSDKToken?.value);
   const isTherapistSDKAuth = computed(() => therapistSDKToken?.value);

   const storeSdkClient = (newToken: string | null) => {
      clientSDKToken.value = newToken;
   };
   const storeSdkTherapist = (newToken: string | null) => {
      therapistSDKToken.value = newToken;
   };

   const clearSdkClient = () => {
      clientSDKToken.value = null;
   };
   const clearSdkTherapist = () => {
      therapistSDKToken.value = null;
   };
   return {
      storeSdkClient,
      clearSdkClient,
      therapistSDKToken,
      clientSDKToken,
      isTherapistSDKAuth,
      isClientSDKAuth,
      storeSdkTherapist,
      clearSdkTherapist,
   };
};
