import apiRoutes from '@/const/apiRoutes';
import type {
   LastSessionResponse,
   SessionDetailResponse,
} from '~/types/BookingResponse';

export const useTherapistVideoStore = defineStore('therapistVideoStore', () => {
   const { setSnackbarDetail } = useSnackbarStore();
   const { storeSdkTherapist, therapistSDKToken } = useZoomTokenStorage();
   const { startZoomCall } = useVideoSDKStore();
   const { therapistInfo } = storeToRefs(useTherapistInfoStore());
   const upcomingBookingAsTherapist = ref<LastSessionResponse | null>(null);
   const sessionDetailAsTherapist = ref<SessionDetailResponse | null>(null);

   const userName = computed(
      () =>
         therapistInfo.value?.first_name +
            ' ' +
            therapistInfo.value?.last_name || ''
   );
   const userSdkToken = computed(() => therapistSDKToken.value || '');

   const startZoomCallAsTherapist = async (sessionId: string) => {
      await startZoomCall(sessionId, userSdkToken.value, userName.value);
   };
   const getUpcomingSessionAsTherapist = async () => {
      const { data, error } = await useTherapistApi<LastSessionResponse>(
         apiRoutes?.therapist?.sessions?.latest
      );
      upcomingBookingAsTherapist.value = data.value;
      if (error?.value) {
         setSnackbarDetail({
            show: true,
            title: error.value.data?.message,
            type: 'error',
         });
         return;
      }
   };
   const getSessionDetailAsTherapist = async (sessionId: string) => {
      const { data, error } = await useTherapistApi<any>(
         apiRoutes?.therapist?.sessions?.detail(sessionId)
      );
      if (sessionId) {
         sessionDetailAsTherapist.value = {
            id: sessionId,
            meeting_link: data.value?.meeting?.link,
            ...data.value,
         };
      }
      if (error?.value) {
         setSnackbarDetail({
            show: true,
            title: error.value.data?.message,
            type: 'error',
         });
         return;
      }
   };
   const getZoomSDKTokenAsTherapist = async (topic: string) => {
      const { data, error } = await useTherapistApi<{ token: string }>(
         apiRoutes?.integrations.zoomToken,
         {
            query: {
               topic,
            },
         }
      );
      storeSdkTherapist(data?.value?.token || null);
      if (error?.value) {
         setSnackbarDetail({
            show: true,
            title: error.value.data?.message,
            type: 'error',
         });
      }
   };

   const attendSessionAsTherapist = async (
      sessionId: string,
      payload: { method: string; attended: boolean }
   ) => {
      const { data, error } = await useTherapistApi(
         apiRoutes?.therapist?.sessions?.updateSession(sessionId),
         {
            method: 'PUT',
            body: payload,
         }
      );
      if (error?.value) {
         setSnackbarDetail({
            show: true,
            title: error.value.data?.message,
            type: 'error',
         });
      }
   };

   return {
      getZoomSDKTokenAsTherapist,
      attendSessionAsTherapist,
      startZoomCallAsTherapist,
      getUpcomingSessionAsTherapist,
      upcomingBookingAsTherapist,
      sessionDetailAsTherapist,
      getSessionDetailAsTherapist,
   };
});
