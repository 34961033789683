export enum SessionStatusEnum {
   completed = 'completed',
   client_no_show = 'client_no_show',
   other = 'other',
}
export const SessionStatusItems = [
   {
      value: SessionStatusEnum.completed,
      label: 'Completed Successfully',
   },
   {
      value: SessionStatusEnum.client_no_show,
      label: 'Unsuccessful - Client did not join',
   },

   {
      value: SessionStatusEnum.other,
      label: 'Other',
   },
];
