import ZoomVideo, { VideoClient, type Participant } from '@zoom/videosdk';
import { SessionStatusEnum } from '~/const/SessionStatusItems';
import apiRoutes from '~/const/apiRoutes';
export const useVideoSDKStore = defineStore('videoSDKStore', () => {
   const { setSnackbarDetail } = useSnackbarStore();
   const router = useRouter();
   const devices: Ref<void | MediaDeviceInfo[] | null> = ref(null);
   const videoSDK: Ref<typeof VideoClient | null> = ref(null);
   const participants: Ref<Participant[]> = ref([]);
   const currentUser: Ref<Participant | undefined> = ref();
   const videoDevices = computed(() => {
      return devices.value?.filter(
         (device: MediaDeviceInfo) => device.kind === 'videoinput'
      );
   });
   const microphoneDevices = computed(() => {
      return devices.value?.filter(
         (device: MediaDeviceInfo) => device.kind === 'audioinput'
      );
   });
   const speakerDevices = computed(() => {
      return devices.value?.filter(
         (device: MediaDeviceInfo) => device.kind === 'audiooutput'
      );
   });

   const getUsers = () => {
      participants.value = videoSDK.value?.getAllUser() || [];
   };
   const getCurrentUser = () => {
      currentUser.value = videoSDK.value?.getCurrentUserInfo();
      return currentUser.value;
   };

   const initZoomSdk = async () => {
      videoSDK.value = ZoomVideo.createClient();
      try {
         await videoSDK.value?.init('en-US', 'Global', {
            patchJsMedia: true,
            stayAwake: true,
            leaveOnPageUnload: true,
            enforceMultipleVideos: true,
            enforceVirtualBackground: true,
         });
         await getMediaDevices();
      } catch (error) {
         setSnackbarDetail({
            show: true,
            title: error,
            type: 'error',
         });
      }
   };
   const startZoomCall = async (
      sessionId: string,
      userSdkToken: string,
      userName: string
   ) => {
      try {
         await videoSDK.value?.join(sessionId, userSdkToken, userName, '', 50);
         Promise.all([getUsers(), getCurrentUser()]);
      } catch (error) {
         setSnackbarDetail({
            show: true,
            title: error,
            type: 'error',
         });
      }
   };
   const getMediaDevices = async () => {
      devices.value = await ZoomVideo.getDevices().catch((error) => {
         setSnackbarDetail({
            show: true,
            title: error,
            type: 'error',
         });
      });
   };
   const leaveCall = (isClient?: boolean, noRedirect?: boolean) => {
      if (videoSDK.value) {
         videoSDK.value?.leave();
         usePusherStore().sendTriggerForLeavingCall(isClient);
      }
      if (!noRedirect) {
         if (isClient) {
            router.push('/client/session');
         } else {
            router.push('/therapist/session');
         }
      }
   };

   const attendSession = async (
      sessionId: string,
      method: string,
      isClient: boolean
   ) => {
      if (isClient) {
         await useClientVideoStore().attendSessionAsClient(sessionId, {
            method: method,
            attended: true,
         });
      } else {
         await useTherapistVideoStore().attendSessionAsTherapist(sessionId, {
            method: method,
            attended: true,
         });
      }
      await getUpcomingSession(isClient);
   };
   const sendReportStatus = async (
      sessionId: string,
      category: string,
      description: string
   ) => {
      const { error } = await useTherapistApi(
         apiRoutes?.therapist?.sessions?.report(sessionId),
         {
            method: 'POST',
            body: {
               category: category,
               description: description,
            },
         }
      );

      if (error?.value) {
         setSnackbarDetail({
            show: true,
            title: error.value.data?.message,
            type: 'error',
         });
         return;
      } else {
         setSnackbarDetail({
            show: true,
            title:
               category == SessionStatusEnum.completed ?
                  'Session completed successfully'
               :  'Session reported successfully',
            type: 'message',
         });
      }
   };
   const getUpcomingSession = async (isClient: boolean) => {
      if (isClient) {
         await useClientVideoStore().getUpcomingSessionAsClient();
      } else {
         await useTherapistVideoStore().getUpcomingSessionAsTherapist();
      }
   };
   const getSessionDetail = async (sessionId: string, isClient: boolean) => {
      if (isClient) {
         await useClientVideoStore().getSessionDetailAsClient(sessionId);
      } else {
         await useTherapistVideoStore().getSessionDetailAsTherapist(sessionId);
      }
   };
   const addLogReport = async (
      isClient: boolean,
      sessionId: string,
      action: string,
      message?: string,
      info?: any
   ) => {
      let apiCall = isClient ? useIFetch : useTherapistApi;
      let urlApi = null;
      if (isClient) {
         urlApi = await apiCall(apiRoutes?.client?.sessions?.log(sessionId), {
            method: 'post',
            body: {
               action: action,
               message: message,
               info: info,
            },
         });
      } else {
         urlApi = await apiCall(
            apiRoutes?.therapist?.sessions?.log(sessionId),
            {
               method: 'post',
               body: {
                  action: action,
                  message: message,
                  info: info,
               },
            }
         );
      }
      if (!urlApi) return;
      let { error } = await urlApi;

      if (error?.value) {
         setSnackbarDetail({
            show: true,
            title: error.value.data?.message,
            type: 'error',
         });
         return;
      }
   };
   return {
      videoDevices,
      speakerDevices,
      microphoneDevices,
      getMediaDevices,
      initZoomSdk,
      startZoomCall,
      videoSDK,
      participants,
      getUsers,
      getCurrentUser,
      currentUser,
      leaveCall,
      attendSession,
      sendReportStatus,
      getUpcomingSession,
      addLogReport,
      getSessionDetail,
   };
});
