import type {
   LastSessionResponse,
   SessionDetailResponse,
} from '~/types/BookingResponse';
import apiRoutes from '@/const/apiRoutes';

export const useClientVideoStore = defineStore('clientVideoStore', () => {
   const { setSnackbarDetail } = useSnackbarStore();
   const { storeSdkClient, clientSDKToken } = useZoomTokenStorage();
   const { startZoomCall } = useVideoSDKStore();
   const { clientProfile } = storeToRefs(useClientInfo());
   const upcomingBookingAsClient = ref<LastSessionResponse | null>(null);
   const sessionDetailAsClient = ref<SessionDetailResponse | null>(null);
   const userName = computed(
      () =>
         clientProfile.value?.username ||
         clientProfile.value?.first_name +
            ' ' +
            clientProfile.value?.last_name ||
         ''
   );
   const userSdkToken = computed(() => clientSDKToken.value || '');

   const startZoomCallAsClient = async (sessionId: string) => {
      await startZoomCall(sessionId, userSdkToken.value, userName.value);
      await attendSessionAsClient(sessionId, {
         method: 'normal',
         attended: true,
      });
   };

   const getUpcomingSessionAsClient = async () => {
      const { data, error } = await useIFetch<LastSessionResponse>(
         apiRoutes?.client?.sessions?.latest
      );
      upcomingBookingAsClient.value = data.value;
      if (error?.value) {
         setSnackbarDetail({
            show: true,
            title: error.value.data?.message,
            type: 'error',
         });
         return;
      }
   };
   const getSessionDetailAsClient = async (sessionId: string) => {
      const { data, error } = await useIFetch<any>(
         apiRoutes?.client?.sessions?.detail(sessionId)
      );
      if (sessionId) {
         sessionDetailAsClient.value = {
            id: sessionId,
            meeting_link: data.value?.meeting?.link,
            ...data.value,
         };
      }
      if (error?.value) {
         setSnackbarDetail({
            show: true,
            title: error.value.data?.message,
            type: 'error',
         });
         return;
      }
   };

   const getZoomSDKTokenAsClient = async (topic: string) => {
      const { data, error } = await useIFetch<{ token: string }>(
         apiRoutes?.integrations.zoomToken,
         {
            query: {
               topic,
            },
         }
      );
      storeSdkClient(data?.value?.token || null);
      if (error?.value) {
         setSnackbarDetail({
            show: true,
            title: error.value.data?.message,
            type: 'error',
         });
      }
   };

   const attendSessionAsClient = async (
      sessionId: string,
      payload: { method: string; attended: boolean }
   ) => {
      const { data, error } = await useIFetch(
         apiRoutes?.client?.sessions?.updateSession(sessionId),
         {
            method: 'PUT',
            body: {
               ...payload,
            },
         }
      );
      if (error?.value) {
         setSnackbarDetail({
            show: true,
            title: error.value.data?.message,
            type: 'error',
         });
      }
   };
   const sendClientFeedback = async (
      sessionId: string,
      payload: { rating: number; feedback: string }
   ) => {
      const { data, error } = await useIFetch(
         apiRoutes?.client?.sessions?.updateSession(sessionId),
         {
            method: 'PUT',
            body: {
               ...payload,
            },
         }
      );
      if (error?.value) {
         setSnackbarDetail({
            show: true,
            title: error.value.data?.message,
            type: 'error',
         });
      } else {
         setSnackbarDetail({
            show: true,
            title: 'Review submitted successfully',
            type: 'message',
         });
      }
   };
   return {
      getZoomSDKTokenAsClient,
      getUpcomingSessionAsClient,
      upcomingBookingAsClient,
      attendSessionAsClient,
      startZoomCallAsClient,
      sendClientFeedback,
      getSessionDetailAsClient,
      sessionDetailAsClient,
   };
});
